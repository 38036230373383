import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components'

import Layout from '../components/Layout';
import HeroHalfImageBg from '../components/HeroHalfImageBg';
import Container from '../components/Container';
import SEO from '../components/SEO';
import BannerSimple from '../components/BannerSimple';
import theme from '../styles/theme';

const PartershipBlockWrap = styled.div`
  ul, li {
    list-style: none;
  }
`;

const Logos = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const Logo = styled.a`
  margin: 15px;
  display: block;
  justify-content: center;
  align-items: center;
  width: 25vw;
  height: 25vw;
  max-width: 200px;
  max-height: 200px;
  margin: 15px;
  position: relative;
  border: 2px solid ${props => props.theme.color.lightGrey};
  border-radius: 5px;
  
  :hover {
    border: 2px solid ${props => props.theme.color.primary};
    img {
      width: 60%;
    }
  }

  img {
    width: 55%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s ease;
  }
`;

const Index = ({ data }) => {

  const { hero, seo, simpleBanner, headingNormalColor, headingAccentColor, description, industries } = data.contentfulPartnershipsPage

  const bg = "white-bg";
  
  return (
    <Layout>
      {seo && <SEO pagePath="about" postNode={seo} pageSEO />}
      {/* <SEO /> */}
      <Container>
        <HeroHalfImageBg id={hero.id} />

        <PartershipBlockWrap className={`pricing-area ${bg} section-ptb`}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 text-center">
                <div className="section-title">
                  <h2 className="mb-15">{headingNormalColor} <span className="theme-color">{headingAccentColor}</span></h2>
                  <div dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}/>
                </div>
              </div>
            </div>
            {industries.map((industry, i) => {
              return (
                <div key={industry.id}>
                  <div key={industry.id} className="row">
                    <div className="col-xs-12 text-center">
                      <div className="section-title mt-50 mb-15">
                        <h3>{industry.name}</h3>
                      </div>
                    </div>
                  </div>
                  <Logos>
                    {industry.companies.map((company, i) => {
                      return (
                        <div key={company.id+industry.id}>
                                <Logo href={company.websiteUrl} target="_blank" rel="noopener">
                                  <img src={company.logo.file.url} alt={company.logo.title} />
                                </Logo>
                        </div>
                      )
                    })}
                  </Logos>
                </div>
              )
              
            })}
        </div>
      </PartershipBlockWrap>
        <BannerSimple id={simpleBanner.id} />
        
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPartnershipsPage {
    industries {
      id
      name
      companies {
        id
        name
        websiteUrl
        logo {
          title
          file {
            url
          }
        }
      }
    }
    hero {
      id
      internal {
        type
      }
    }
    seo {
      pageTitle
      shareImage {
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      metaDescription {
        metaDescription
      }
      metaKeywords {
        metaKeywords
      }
    }
    simpleBanner {
      id
    }
    headingNormalColor
    headingAccentColor
    description {
      childMarkdownRemark {
        html
      }
    }
  }
  }
`

export default Index

import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import { rgba } from 'polished'

import theme from '../styles/theme'
import { media } from '../styles/utils'

const HeroWrap = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  height: calc(70vh - 110px);
  width: 100%;

  ${media.large`
    height: auto;
    grid-template-columns: 1fr;
  `}
`

const HeroTextWrap = styled.div`
  background-color: ${(props) => props.theme.color.lightGrey};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative;

  ${media.large`
    min-height: 50vh;
  `}

  > h1 {
    color: ${(props) => props.theme.color.textMain};
    padding: 40px;
    padding: 0 10px;
    font-size: 60px;
    line-height: 60px;
    z-index: 2;
    text-align: center;
    width: 100%;
  }
`

const FadedBGImage = styled(Img)`
  width: 100%;
  height: 100%;
  /* background-image: url(${(props) => props.img}); */
  background-size: cover;
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0.25;
`

const BGImageWrap = styled.div`
  position: relative;

  ${media.large`
    min-height: 50vh;
  `}
`

const BGImage = styled(Img)`
  width: 100%;
  height: 100%;
  /* background-image: url(${(props) => props.img}); */
  background-size: cover;
  position: absolute !important;
  top: 0;
  right: 0;
`

const HeroH1 = styled.h1`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.color.textMain};

  > div p {
    color: ${(props) => props.theme.color.textMain} !important;
    /* text-transform: uppercase; */
    font-size: 50px;
    line-height: 60px;

    ${media.small`
      font-size: 30px;
    `}
  }
`

const HeroLink = styled(Link)`
  margin-top: 30px;

  ${media.small`
    width: 100%;
    margin-left: 0 !important;
  `}
`

const ButtonWrap = styled.div`
  display: flex;

  ${media.small`
    flex-direction: column;
  `}
`

const HeroHalfImageBg = (props) => (
  <StaticQuery
    query={graphql`
      query HeroHalfImageBgQuery {
        allContentfulHeroSplit {
          edges {
            node {
              id
              text {
                childMarkdownRemark {
                  html
                }
              }
              image {
                title
                fluid(maxWidth: 1800) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
              buttonText
              button2Text
              buttonUrl
              button2Url
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          {data.allContentfulHeroSplit.edges.map(({ node: hero }) => {
            if (hero.id === props.id) {
              const heroHeading = hero.text.childMarkdownRemark.html.replace(
                /\n/g,
                '<br />'
              )
              return (
                <HeroWrap key={hero.id}>
                  <HeroTextWrap>
                    <HeroH1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: heroHeading,
                        }}
                      />
                    </HeroH1>
                    <ButtonWrap>
                      {hero.buttonText && hero.buttonUrl && (
                        <HeroLink className="btn theme-btn" to={hero.buttonUrl}>
                          {hero.buttonText}
                        </HeroLink>
                      )}
                      {hero.button2Text && hero.button2Url && (
                        <HeroLink
                          className="btn theme-btn"
                          to={hero.button2Url}
                        >
                          {hero.button2Text}
                        </HeroLink>
                      )}
                    </ButtonWrap>
                  </HeroTextWrap>
                  <BGImageWrap>
                    <BGImage fluid={hero.image.fluid} />
                  </BGImageWrap>
                </HeroWrap>
              )
            }
          })}
        </>
      )
    }}
  />
)
export default HeroHalfImageBg
